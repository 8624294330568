import iconPdf from '@assets/img/icon_pdf.svg';
import iconWord from '@assets/img/icon_word.svg';
import iconExcelGrey from '@assets/img/icon_excel_grey.svg';
import iconPowerpointGrey from '@assets/img/icon_powerpoint_grey.svg';
import iconExternalLink from '@assets/img/icon_external_link.svg';
import PhotoIcon from '@mui/icons-material/Photo';
import settings from '@config/settings';

import './attachment.scss';

const getAttachmentName = (att) => {
  let name = att.description;

  if (!name && att.url) {
    name = att.url;
  }
  if (!name && att.href) {
    name = att.href.split('/').pop();
  }

  if (!name && att.name) {
    name = att.name;
  }

  return name;
};

const getAttachmentUrl = (att) => {
  console.log({ att });
  let url = att?.href;
  if (!att?.href?.includes('http')) {
    url = settings.apisAndUrls.api + att.href;
  }

  return url;
};

const getCustomSvgIcon = (type) => {
  const ICONS = {
    'application/pdf': iconPdf,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (
      <img src={iconWord} />
    ),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
      <img src={iconExcelGrey} />
    ),
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': (
      <img src={iconPowerpointGrey} />
    ),
  };

  return ICONS[type] || iconExternalLink;
};

const Attachment = ({ item }) => {
  const url = getAttachmentUrl(item);
  const name = getAttachmentName(item);
  if (item.contentType === '') {
    if (name.includes('xlsx') !== -1)
      item.contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    else if (name.includes('pptx') !== -1)
      item.contentType =
        'application/vnd.openxmlformats-officedocument.presentationml.presentation';
  }
  const type = item.contentType || item.type;
  const isImage = type.startsWith('image/');

  return (
    <div className="attachment">
      <a href={url} target="_blank" rel="noreferrer" onClick={(ev) => ev.stopPropagation()}>
        {isImage && <PhotoIcon className="image-icon" />}
        {!isImage && (
          <div className="icon">
            <img src={getCustomSvgIcon(type)} />
          </div>
        )}
        <div className="name">{name}</div>
      </a>
    </div>
  );
};

export default Attachment;
