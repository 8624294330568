import commonUtils from '@kathondvla/sri-client/common-utils';
import { generalLoadError, generalSaveError } from '@store/alerts/alertConstants';
import { setAlert } from '@store/alerts/alertsState';
import { api, customCurApi, forceLogin } from '@store/apihelpers';
import { selectLlinkidCurriculaRoots } from '@store/contentApi/contentApiSelectors';
import {
  selectActivityPlansData,
  selectCustomCurriculaData,
  selectIsLlinkidInitializing,
} from '@store/llinkidApis/llinkidApiSelectors';
import { saveLlinkidApiBatch } from '@store/llinkidApis/llinkidApiState';
import { selectIsStudyProgrammeDataLoaded } from '@store/studyProgrammesApi/studyProgrammeSelectors';
import {
  selectActiveSamResponsibilitiesForSchool,
  selectCurrentSchool,
  selectCurrentSchoolData,
  selectCurrentSchoolHref,
  selectCurrentSchoolyear,
  selectIsDirector,
  selectIsDirectorInList,
  selectIsUserAndSchoolStateInitialized,
  selectLastActiveDateForSchool,
  selectSchoolyearsForSchool,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { filterByOrgs, filterCustomCurriculaWithFilters } from '@utils/filters';
import { channelSelectorEmitter, waitForSaga } from '@utils/sagaUtils';
import changeCreatorsForPlan from '@utils/userAndSchoolHelper';
import { getSchoolyears, getVskoReqHeader } from '@utils/utils';
import { filterResourcesActiveOnDate } from '@utils/filterResourcesActiveOnDate';
import { flatten, last } from 'lodash-es';

import { channel } from 'redux-saga';
import {
  call,
  debounce,
  fork,
  put,
  select,
  spawn,
  take,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import iconExternalLinkWhite from '@assets/img/icon_external_link_white.svg';
import settings from '@config/settings';
import { logAndCaptureException } from '@utils/logAndCaptureException';
import { saveState } from './persistUserSchoolState';
import {
  checkAndFixStudyProgrammeGroups,
  deDupeLeidraadChoices,
  filterSamResps,
  getAllPersonsFromExpandedResponsibilities,
  getMe,
  getOrgsForResps,
  getPrivateState,
  getResponsibilities,
  savePreferences,
} from './userAndSchoolDataAccess';
import {
  init,
  leidraadChoicesFetched,
  orgsFetched,
  preferencesFetched,
  responsibilitiesInSchoolFetched,
  samResponsibilitiesFetched,
  schoolDetailsFetchingFailed,
  setAutoStarredPreferences,
  setCollapseSectionsPreferences,
  setCurrentSchoolyear,
  setHideOptionalElementsPreferences,
  setModalsPreferences,
  setOrgContext,
  setSchoolContext,
  setStarredCurriculaPreferences,
  studyProgrammeGroupsFetched,
  teachersForTeamsFetched,
  teachersInSchoolFetched,
  userFetched,
  userFetchFailed,
  userPersonalDataFetched,
} from './usersAndSchoolState';

function* getPerson(href) {
  try {
    const person = yield api.get(href);
    yield put(userPersonalDataFetched(person));
  } catch (personError) {
    yield put(userPersonalDataFetched(personError));
  }
}

async function checkForceLogin() {
  const params = Object.fromEntries(
    new URLSearchParams(
      window.location.hash.startsWith('/#!')
        ? window.location.hash.substring(window.location.hash.indexOf('?') + 1)
        : window.location.search
    ).entries()
  ); // getting the params from the angularjs-style-url
  if (params.forceLogin === 'true') {
    console.log(params);
    const fresh = params.forceLogout === 'true';
    console.log('forcelogin activated from saga.');
    window.location.href = forceLogin(
      window.location.href,
      fresh,
      params.loginProvider,
      params.loginProviderArgument
    );
    // $oauth.disableInterceptor();

    // eslint-disable-next-line no-promise-executor-return
    await new Promise((e) => setTimeout(e, 5000)); // /wait for the page to load, chrome doesnt stop execution...
    console.error('forcelogin js still executing after 5 seconds');
    return true;
  }
  return false;
}

function* initUserData() {
  try {
    yield call(checkForceLogin);
    // this forcelogin is only called once when loading the app.
    // we have this checkForceLogin logic also in the userservice and the router,
    // where it will run on every route change. This is to be cleaned up later TODO

    const me = yield call(getMe);
    if (me) {
      me.key = me.uuid;
      delete me.uuid;
      me.$$meta = {
        permalink: `/persons/${me.key}`,
      };
    }
    yield put(userFetched(me));
    if (me) {
      yield spawn(getPerson, `/persons/${me.key}`);
    }
  } catch (error) {
    logAndCaptureException(error);
    yield put(userFetchFailed()); // TODO
  }
}

function* getSchoolData({ payload }) {
  try {
    const orgs = yield getOrgsForResps(payload);
    yield put(orgsFetched(orgs.filter((e) => e.type === 'SCHOOLENTITY')));
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* getPreferences({ payload }) {
  try {
    if (payload && payload.$$meta) {
      const privateState = yield getPrivateState(payload);
      yield put(preferencesFetched(privateState));
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* getActiveSamResponsibilities({ payload }) {
  try {
    if (payload && payload.$$meta) {
      const resps = yield getResponsibilities(payload.$$meta.permalink);
      const filteredResps = filterSamResps(resps);
      // QUESTION: should I filter the api result here, before sending it to the state, or should it be done at the action itself...
      // if I do it here, it's easier to intercept the responsibilitiesFetched because it already has the correct info to then fetch the schools
      yield put(samResponsibilitiesFetched(filteredResps)); // <== we need to rename the action, they are no longer just the active ones.
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* setRespAndTeachers(school) {
  try {
    const latestEndDate = yield select((state) =>
      selectLastActiveDateForSchool(state, school.$$meta.permalink)
    );

    const params = {
      organisations: school.$$meta.permalink,
      expandPerson: 'FULL',
      expandPosition: 'none',
    };

    // the date needs to be the (latest) enddate this person has on the school.
    if (latestEndDate !== null) {
      params.date = new Date(latestEndDate).toISOString();
    }

    let responsibilities = yield api.getAll('/responsibilities', params, getVskoReqHeader());

    const teachers = getAllPersonsFromExpandedResponsibilities(responsibilities);
    responsibilities = responsibilities.map((e) => ({ ...e, person: { href: e.person.href } })); // remove the expanded teachers from the resps.
    yield put(
      responsibilitiesInSchoolFetched({
        href: school.$$meta.permalink,
        responsibilities,
      })
    );
    yield put(teachersInSchoolFetched({ href: school.$$meta.permalink, teachers }));
  } catch (ex) {
    logAndCaptureException(ex);
    yield put(schoolDetailsFetchingFailed({ href: school.$$meta.permalink }));
  }
}

function* setLeidraadChoices(school) {
  try {
    const leidraadChoices = yield customCurApi.getAll(
      '/llinkid/qualityinstrument/choices',
      { 'creator.href': school.$$meta.permalink },
      getVskoReqHeader()
    );
    const leidraadChoicesFixed = yield deDupeLeidraadChoices(leidraadChoices);
    yield put(
      leidraadChoicesFetched({
        href: school.$$meta.permalink,
        leidraadChoices: leidraadChoicesFixed,
      })
    );
  } catch (ex) {
    logAndCaptureException(ex);
    yield put(schoolDetailsFetchingFailed({ href: school.$$meta.permalink }));
  }
}

function* setTeachersForTeams(school) {
  try {
    const latestEndDate = yield select((state) =>
      selectLastActiveDateForSchool(state, school.$$meta.permalink)
    );

    const allSamResponsibilities = yield select(
      (state) => state.userAndSchools.samResponsibilities
    );
    const activeSamResponsibilitiesOnLastDay = filterResourcesActiveOnDate(
      allSamResponsibilities,
      latestEndDate
    );

    const isDirector = selectIsDirectorInList(
      activeSamResponsibilitiesOnLastDay,
      school.$$meta.permalink
    );
    const usableTeams = school.teacherGroups.filter(
      (e) =>
        isDirector ||
        activeSamResponsibilitiesOnLastDay.some((or) => or.organisation.href === e.$$meta.permalink)
    );
    const allTeamResps = yield api.getAllReferencesTo(
      '/responsibilities',
      { expandPosition: 'none', date: latestEndDate },
      'organisations',
      usableTeams.map((t) => t.$$meta.permalink)
    );
    yield put(teachersForTeamsFetched({ href: school.$$meta.permalink, allTeamResps }));
  } catch (ex) {
    logAndCaptureException(ex);
    yield put(schoolDetailsFetchingFailed({ href: school.$$meta.permalink }));
  }
}

function* setCustomStudyProgrammeGroups(school) {
  try {
    const studyProgrammeGroups = yield customCurApi.getAll(
      '/llinkid/customstudyprogrammegroups',
      { 'creator.href': school.$$meta.permalink },
      getVskoReqHeader()
    );

    yield call(waitForSaga, (state) => selectIsStudyProgrammeDataLoaded(state) === true);

    const firstGradeStreams = yield select(
      (state) => state.studyProgrammeApiData.firstGradeStreams
    );
    const secondAndThirdGradeFinalities = yield select(
      (state) => state.studyProgrammeApiData.secondGradeFinalities
    );

    const allPrograms = yield select((state) => state.studyProgrammeApiData.allPrograms);

    const fixedstudyProgrammeGroups = yield checkAndFixStudyProgrammeGroups(
      school,
      studyProgrammeGroups,
      firstGradeStreams,
      secondAndThirdGradeFinalities,
      allPrograms
    );
    yield put(
      studyProgrammeGroupsFetched({
        href: school.$$meta.permalink,
        studyProgrammeGroups: fixedstudyProgrammeGroups,
      })
    );
  } catch (ex) {
    logAndCaptureException(ex);
    yield put(schoolDetailsFetchingFailed({ href: school.$$meta.permalink }));
  }
}

function* getResponsibilitiesAndTeachers({ payload }) {
  try {
    for (const school of payload) {
      yield fork(setRespAndTeachers, school);
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* getLeidraadChoices({ payload }) {
  try {
    for (const school of payload) {
      yield fork(setLeidraadChoices, school);
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* getCustomStudyProgrammeGroups({ payload }) {
  try {
    for (const school of payload) {
      yield fork(setCustomStudyProgrammeGroups, school);
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* getTeachersForTeams({ payload }) {
  try {
    for (const school of payload) {
      yield fork(setTeachersForTeams, school);
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* initCurrentSchoolAndOrg({ payload }) {
  try {
    const schoolHref = sessionStorage.getItem('currentSchool');
    const orgHref = sessionStorage.getItem('currentOrg');
    if (payload.find((e) => e.$$meta.permalink === schoolHref)) {
      yield put(setSchoolContext(schoolHref));
      yield put(setOrgContext(orgHref));
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* savePreferencesState() {
  try {
    const user = yield select((state) => state.userAndSchools.user);
    if (user) {
      const privateState = yield select((state) => state.userAndSchools.privateState);
      yield savePreferences(privateState);
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalLoadError));
  }
}

function* autoStarringCurr({ payload = [] }) {
  try {
    yield call(waitForSaga, (state) => selectActiveSamResponsibilitiesForSchool(state)?.length);
    const activeSamResponsibilities = yield select((state) =>
      selectActiveSamResponsibilitiesForSchool(state)
    );
    const isDirector = selectIsDirector(activeSamResponsibilities);
    if (!isDirector) return;

    const [preferences] = payload;
    const autoStarred = preferences?.state?.autoStarred;

    if (autoStarred !== settings.autoStarred) {
      yield call(waitForSaga, (state) => selectLlinkidCurriculaRoots(state).length);
      const curriculaList = yield select((state) => selectLlinkidCurriculaRoots(state));
      const foundationalCurricula = curriculaList?.filter((item) => item.foundational);
      for (const curr of foundationalCurricula) {
        yield put(
          setStarredCurriculaPreferences({ curriculumHref: curr.$$meta.permalink, starred: true })
        );
      }
      yield put(setAutoStarredPreferences({ value: settings.autoStarred }));
    }
  } catch (e) {
    logAndCaptureException(e);
    yield put(setAlert(generalSaveError));
  }
}

function* checkSelectedSchoolyearValidSaga() {
  yield call(waitForSaga, (state) => selectIsUserAndSchoolStateInitialized(state));
  const { schoolYear, schoolYearsForSchool, currentSchoolyear } = yield select((state) => ({
    schoolYear: selectCurrentSchoolyear(state),
    schoolYearsForSchool: selectSchoolyearsForSchool(state),
    currentSchoolyear: state.userAndSchools.currentSchoolyear,
  }));
  if (!schoolYear || !schoolYearsForSchool.find((e) => e.key === schoolYear.key)) {
    const def = schoolYearsForSchool.find((e) => e.default);
    yield put(setCurrentSchoolyear(def.value));
    sessionStorage.setItem('currentSchoolyear', def.value);
    const currentSchoolYearObj = getSchoolyears().find((e) => e.value === currentSchoolyear);
    const alert = {
      key: 'schoolyear-not-found-warning',
      title: 'Opmerking',
      msg: `${
        currentSchoolYearObj?.name
      } bestaat niet in deze school. Je bevindt je nu in ${def.name.toLowerCase()}`,
      type: 'warning',
      showClose: true,
      delay: 10000,
    };
    yield put(setAlert(alert));
  } else if (!schoolYearsForSchool.find((e) => e.key === schoolYear.key).initialized) {
    const initzd = schoolYearsForSchool.filter((e) => e.initialized);
    const def = initzd.find((e) => e.default) ? initzd.find((e) => e.default) : last(initzd);
    yield put(setCurrentSchoolyear(def.value));
    sessionStorage.setItem('currentSchoolyear', def.value);
    const alert = {
      key: 'schoolyear-not-initialized-warning',
      title: 'Opmerking',
      msg: `${
        schoolYear.name
      } is nog niet geïnitialiseerd in deze school. Je bevindt je nu in ${def.name.toLowerCase()}`,
      type: 'warning',
      showClose: true,
      delay: 10000,
    };
    yield put(setAlert(alert));
  }
}

function* checkMergedPersonsSaga() {
  yield call(
    waitForSaga,
    (state) =>
      selectIsUserAndSchoolStateInitialized(state) && selectIsLlinkidInitializing(state) === false
  );

  const { allTeachers, activityPlans } = yield select((state) => ({
    allTeachers: selectCurrentSchoolData(state)?.teachers,
    activityPlans: selectActivityPlansData(state),
  }));

  const teachers = allTeachers.filter((e) => e.mergedPersons?.length);

  // make a map of all old hrefs to new hrefs;
  const mergedMap = new Map();
  teachers.forEach((t) => t.mergedPersons.forEach((m) => mergedMap.set(m, t.$$meta.permalink)));

  const mergedHrefs = flatten(teachers.map((t) => t.mergedPersons));

  const allCustomCurriculas = yield select((state) => selectCustomCurriculaData(state));
  const orgs = mergedHrefs.map((e) => ({ href: e }));
  const customCurricula = filterCustomCurriculaWithFilters(allCustomCurriculas, [
    filterByOrgs(orgs),
  ]);

  const updatedCustomCurricula = customCurricula.map((curriculum) => {
    return {
      ...curriculum,
      creator: {
        ...curriculum.creator,
        href: mergedMap.get(curriculum.creator.href),
      },
    };
  });

  const userActivityPlans = activityPlans.filter(
    (plan) =>
      plan.creators?.some((creator) => mergedHrefs.includes(creator.href)) ||
      plan.observers?.some((observer) => mergedHrefs.includes(observer.href))
  );

  const updatedActivityPlans = userActivityPlans.map((plan) => {
    const newPlan = { ...plan };
    newPlan.creators = changeCreatorsForPlan(newPlan.creators, mergedMap);
    newPlan.observers = changeCreatorsForPlan(newPlan.observers, mergedMap);
    return newPlan;
  });

  if (updatedCustomCurricula.length) {
    const batch = updatedCustomCurricula.map((e) => {
      return { href: e.$$meta.permalink, verb: 'PUT', body: e };
    });
    const batchKey = commonUtils.generateUUID();
    yield put(saveLlinkidApiBatch({ batch, applyInstantly: false, batchKey }));
  }

  if (updatedActivityPlans.length) {
    const batch = updatedActivityPlans.map((e) => {
      return { href: e.$$meta.permalink, verb: 'PUT', body: e };
    });
    const batchKey = commonUtils.generateUUID();
    yield put(saveLlinkidApiBatch({ batch, applyInstantly: false, batchKey }));
  }
}

function* checkSelectedSchool() {
  yield call(
    waitForSaga,
    (state) =>
      selectIsUserAndSchoolStateInitialized(state) && selectIsLlinkidInitializing(state) === false
  );

  const school = yield select(selectCurrentSchool);

  if (!school) {
    return;
  }

  if (school.studyProgrammes?.length === 0) {
    const alert = {
      key: 'no-studyprogrammes-error',
      title: 'Studierichtingen / basisopties ontbreken',
      msg: `Voor deze school zijn de studierichtingen / basisopties nog niet gekend. Deze dienen eerst ingevoerd te worden in <a href="https://mijn.katholiekonderwijs.vlaanderen" target="_blank" ng-if="showExternal">mijn.katholiekonderwijs.vlaanderen <img src="${iconExternalLinkWhite}"></a> door een verantwoordelijke of de directeur. Pas nadien kan je Llinkid ten volle benutten.`,
      type: 'error',
      showClose: true,
      delay: 10000,
    };
    yield put(setAlert(alert));
  }
}

export function* watchSetSchoolContext() {
  let prevSchoolContext = yield select(selectCurrentSchoolHref);

  while (true) {
    const action = yield take(setSchoolContext.type);
    const newSchoolContext = action.payload;

    if (newSchoolContext !== prevSchoolContext) {
      if (newSchoolContext) {
        yield call(checkSelectedSchool);
        yield call(checkMergedPersonsSaga);
      }
      prevSchoolContext = newSchoolContext;
    }
  }
}

export function* watchSetSchoolYear() {
  let prevSchoolYear = yield select(selectCurrentSchoolyear);

  while (true) {
    const action = yield take(setCurrentSchoolyear.type);
    const newSchoolYear = action.payload;

    if (newSchoolYear !== prevSchoolYear) {
      yield call(checkSelectedSchoolyearValidSaga);
      prevSchoolYear = newSchoolYear;
    }
  }
}

export function* watchInitDataSaga() {
  yield takeLatest(init, initUserData);
  yield takeLatest(userFetched, getPreferences);
  yield takeLatest(userFetched, getActiveSamResponsibilities);
  yield takeLatest(samResponsibilitiesFetched, getSchoolData);
  yield takeLatest(orgsFetched, initCurrentSchoolAndOrg);
  yield takeLatest(orgsFetched, getResponsibilitiesAndTeachers);
  yield takeLatest(orgsFetched, getLeidraadChoices);
  yield takeLatest(orgsFetched, getCustomStudyProgrammeGroups);
  yield takeLatest(orgsFetched, getTeachersForTeams);
  yield throttle(
    500,
    [
      setStarredCurriculaPreferences,
      setHideOptionalElementsPreferences,
      setCollapseSectionsPreferences,
      setModalsPreferences,
      setAutoStarredPreferences,
    ],
    savePreferencesState
  );

  yield takeLatest(preferencesFetched, autoStarringCurr);
  yield takeLatest([orgsFetched, setSchoolContext], checkSelectedSchoolyearValidSaga);
}

function updateLocalStorage(data) {
  saveState(data);
}

export function* watchUserAndSchoolStateSaga() {
  const chan = yield call(channel);
  yield fork(channelSelectorEmitter, (state) => state.userAndSchools, chan);

  yield debounce(2000, chan, updateLocalStorage);
}
