import { GoalSimplified } from '@UI/index';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src';
import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton } from '@mui/material';
import './activityGoalSelector.scss';

const ActivityGoalSelector = ({
  goals,
  curricula,
  options,
  onAdd,
  onRemove,
  onChange,
  onLeerplanSelection,
  occurencesPerGoal,
}) => {
  const [showCurrSelector, setShowCurrSelector] = useState(false);

  const filterGoals = ({ data }, str) => {
    if (str.length === 0) return data;
    const regExp = new RegExp(str, 'gi');
    return regExp.test(data.description) || regExp.test(data.identifier);
  };

  const displayGoal = (goal) => {
    if (!goal) return '';
    const description = goal.description ? goal.description.replace(/<[^>]+>/gm, '') : '';

    const display = `${goal.$$count ? `(${goal.$$count})` : `&nbsp;&nbsp;&nbsp;&nbsp;`}${
      goal.identifier
    } | ${description}`;

    return display;
  };

  const onAddHandler = (goal) => {
    const updatedGoals = [...goals, goal];
    onAdd?.(goal);
    onChange?.(updatedGoals);
  };

  const onRemoveHandler = (goal) => {
    const updatedGoals = goals.filter((g) => g.key !== goal.key);
    onRemove?.(goal);
    onChange?.(updatedGoals);
  };

  const goToSelectorForCurr = (curr) => {
    onLeerplanSelection?.(curr);
  };

  const onLeerplanSelectionHandler = () => {
    if (curricula.length > 1) {
      setShowCurrSelector(!showCurrSelector);
    } else {
      setShowCurrSelector(false);
      goToSelectorForCurr(curricula[0]);
    }
  };

  return (
    <div className="activity-goal-selector">
      <div className="goals-picker">
        <div className="resource">
          <KovResourcePicker
            value={null}
            localData={options}
            display={displayGoal}
            filter={filterGoals}
            optionTemplate={(option) => (
              <GoalSimplified
                goal={option}
                count={occurencesPerGoal?.get(option.href) || undefined} //  || undefined is so we don't show 0
              />
            )}
            placeholder="Zoek een doel..."
            portal="body"
            onChange={onAddHandler}
            style={{ menu: (provided) => ({ ...provided, zIndex: 100 }) }}
          />
        </div>
        <span className="small-text"> OF </span>
        <div className="goals-selector">
          <Button
            variant="contained"
            onClick={onLeerplanSelectionHandler}
            disabled={!curricula || curricula.length === 0}
          >
            Selecteer in het leerplan
          </Button>
          {showCurrSelector && (
            <div className="multiple-curr">
              {curricula.map((curr) => (
                <Button
                  variant="outlined"
                  key={curr.name}
                  onClick={() => goToSelectorForCurr(curr)}
                >
                  {curr.name}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="goal-list">
        {goals?.map((goal) => (
          <div className="goal-selected" key={goal.key}>
            <GoalSimplified
              goal={goal}
              count={
                occurencesPerGoal?.get(goal.href) > 1
                  ? occurencesPerGoal?.get(goal.href)
                  : undefined
              } // only show count here if it's > 1
            />
            <div className="remove">
              <IconButton color="primary" onClick={() => onRemoveHandler(goal)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivityGoalSelector;
